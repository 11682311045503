import {
  AddressWsDTO,
  BreadcrumbWsDTO,
  ConsignmentEntryWsDTO,
  ConsignmentWsDTO,
  CountryLocation,
  FacetWsDTO,
  ImageWsDTO,
  OrderEntryWsDTO,
  PaginationWsDTO,
  PriceWsDTO,
  ProductSearchPageWsDTO,
  ProductWsDTO,
  RequestWsDTO,
  SearchStateWsDTO,
  SortWsDTO,
  SpellingSuggestionWsDTO,
  UserSignUpWsDTO,
  UserWsDTO,
} from './ycommercewebservices';
import {RequestOptions} from '../rest-service';

export interface PConfig {
  version?: string;
  clientId?: string;
  clientIdAnonymous?: string;
  clientSecret?: string;
  clientSecretAnonymous?: string;
  debug?: boolean;
  googleTrackingId?: string;
  gtmContainerId?: string;
  apiHost?: URL;
  baseHref?: string;
  adobeTmUrl?: string;
  hotzoneConfiguratorUrl?: string;
}

export interface PRequestWsDTO extends RequestWsDTO, RequestOptions {
  companyId?: string;
  customerView?: boolean;
  country?: string;
  city?: string;
  optionals?: string;
}

export interface PSearchPageWsDTO {
  freeTextSearch?: string;
  categoryCode?: string;
  keywordRedirectUrl?: URL;
  spellingSuggestion?: SpellingSuggestionWsDTO;
  sorts?: SortWsDTO[];
  pagination?: PaginationWsDTO;
  currentQuery?: SearchStateWsDTO;
  breadcrumbs?: BreadcrumbWsDTO[];
  facets?: PFacetWsDTO[];
  historyViewType?: ChartViewType;
}

export interface PUserWsDTO extends UserWsDTO {
  profileImage?: ImageWsDTO;
}

export interface PProductWsDTO extends ProductWsDTO {
  customerMaterialNumber?: string;
  material?: string;
  type?: string;
  unit?: string;
  unitLocalized?: string;
  allowedForSale?: boolean;
  discontinuedProduct?: boolean;
  purchasable?: boolean;
}

export enum FacetWidgetType {
  MULTI_SELECT = 'MULTI_SELECT',
  SINGLE_SELECT = 'SINGLE_SELECT',
  SLIDER = 'SLIDER',
  RANGE = 'RANGE',
  DATE = 'DATE',
  FOLDER = 'FOLDER',
  ROOT_FOLDER = 'ROOT_FOLDER'
}

export interface PFacetWsDTO extends FacetWsDTO {
  widgetType?: FacetWidgetType;
  unit?: string;
}

export interface PProductSearchPageWsDTO extends ProductSearchPageWsDTO {
  products?: PProductWsDTO[];
  facets?: PFacetWsDTO[];
}

export enum PShipmentStatus {
  ISSUE = 'ISSUE',
  MODERATE_DELAY = 'MODERATE_DELAY',
  ON_TIME = 'ON_TIME',
  SHIPPED = 'SHIPPED'
}

export enum PPaymentStatus {
  PAID = 'PAID',
  UNPAID = 'UNPAID',
  OVERDUE = 'OVERDUE',
  STORNO = 'STORNO'
}

export interface POrderMinimalDataWsDTO {
  status?: PShipmentStatus;
  orderCode?: string;
  orderCodePosition?: string;
  purchaseOrderNumber?: string;
}

export interface POrderEntryWsDTO extends OrderEntryWsDTO {
  hasChangedShipmentInfo?: boolean;
  shipmentInfoExpanded?: boolean;
  shipmentStatus?: PShipmentStatus;
  requestedDate?: string;
  planseeQuantity?: number;
  unit?: string;
  unitCode?: string;
  order?: POrderMinimalDataWsDTO;
  consignmentEntries?: PConsignmentEntryWsDTO[];
  product?: PProductWsDTO;
  hasShipments?: boolean;
  consignmentSchedules?: PConsignmentScheduleWsDTO[];
  plannedDeliveries?: PConsignmentScheduleEntryWsDTO[]; // use for v2 details
  hasSchedules?: boolean;
  rejected?: boolean;
  customPONumber?: string;
}

export interface POrderEntrySearchPageWsDTO extends PSearchPageWsDTO {
  orderEntries?: POrderEntryWsDTO[];
}

export interface PInvoiceWsDTO {
  paymentStatus?: PPaymentStatus;
  invoiceNumber?: number;
  poNumber?: number[];
  poNumbersAvailability?: boolean[];
  paymentDueDate?: Date;
  invoiceDate?: string;
  amount?: PriceWsDTO;
  dunningNote?: string;
  dunningNoteId?: string;
  documentId?: string;
  invoiceType?: string;
  salesDocumentType?: string;
  invoiceCategory?: string;
}

export interface PInvoiceEntrySearchPageWsDTO extends PSearchPageWsDTO {
  invoices?: PInvoiceWsDTO[];
}

export interface PConsignmentWsDTO extends ConsignmentWsDTO {
  shippingDate?: Date;
  deliveryDate?: Date;
  trackingUrl?: URL;
}

export interface PConsignmentEntryWsDTO extends ConsignmentEntryWsDTO {
  consignment?: PConsignmentWsDTO;
  orderEntry?: POrderEntryWsDTO;
  planseeQuantity?: number;
  shippedQuantity?: number;
  sapShipmentPositionId?: string;
  unit?: string;
  unitCode?: string;
}

export interface PConsignmentEntryListWsDTO {
  consignments?: PConsignmentEntryWsDTO[];
}

// result for POrdersService.getConsignmentEntryDetails
export interface PConsignmentEntryDetailsWsDTO {
  shipmentSchedule?: PConsignmentScheduleListWsDTO;
  shipmentTrackings?: PConsignmentEntryListWsDTO;
}

// result for PlanseeOrdersService.getConsignmentEntryDetails
export interface PConsignmentEntryDetailsWsDTOV2 {
  plannedDeliveries?: PConsignmentScheduleEntryWsDTO[];
  shipmentTrackings?: PConsignmentEntryListWsDTO;
}

export interface PConsignmentScheduleEntryWsDTO {
  date?: Date | string;
  dateIssue?: boolean;
  historical?: boolean;
  quantity?: number;
  quantityIssue?: boolean;
}

export interface PConsignmentScheduleWsDTO {
  modificationTime?: Date | string;
  plannedDeliveries?: PConsignmentScheduleEntryWsDTO[];
}
export interface PConsignmentScheduleListWsDTO {
  code?: string;
  planseeScheduleEntries?: PConsignmentScheduleWsDTO[];
}

export interface PPurchaseOrdersWsDTO {
  orderEntry?: POrderEntryWsDTO;
  orderCodePosition?: string;
  planseeOrderNumber?: string;
  planseeQuantity?: number;
  purchaseNumber?: string;
  purchaseNumberAvailability?: boolean;
  sapShipmentPositionId?: string;
  unit?: string;
  unitCode?: string;
}

export interface PShipmentWsDTO {
  code?: string;
  deliveryMode?: string;
  documentId?: string;
  entries?: PPurchaseOrdersWsDTO[];
  goodsIssueDate?: Date | string;
  handedDate?: Date | string;
  inTransitDate?: Date | string;
  namedDeliveryDate?: Date | string;
  plannedArrivalDate?: Date | string;
  planseeQuantity?: number;
  purchaseNumPosition?: string;
  quantity?: number;
  trackingUrl?: URL | string;
  transitDate?: Date | string;
  unit?: string;
  unitCode?: string;
}

export interface PShipmentEntrySearchPageWsDTO extends PSearchPageWsDTO {
  consignments?: PShipmentWsDTO[];
}

export enum PForecastEntryValueTypeWsDTO {
  NORMAL = 'NORMAL',
  DOES_NOT_MEET_FORECAST = 'DOES_NOT_MEET_FORECAST'
}

export interface PForecastEntryWsDTO {
  firstDayOfPeriod?: Date;
  forecastQuantity?: number;
  forecastQuantityType?: PForecastEntryValueTypeWsDTO;
  orderedQuantity?: number;
  quantityDeviation?: number;
}

export interface PContractWsDTO {
  contractMaximumQuantity?: number;
  contractCurrentQuantity?: number;
  contractStartDate?: Date;
  contractEndDate?: Date;
  unit?: string;
}

export interface PForecastWsDTO extends PContractWsDTO {
  materialNumber?: number;
  companyMaterialNr?: number;
  description?: string;
  segments?: PForecastEntryWsDTO[];
  unitOfMeasurement?: string;
}

export interface PForecastingSearchPageWsDTO extends PSearchPageWsDTO {
  productHistoryForecastRecords?: PForecastWsDTO[];
}

export interface PVolumeHistoryWsDTO {
  segments?: PForecastEntryWsDTO[];
  historyViewType?: string;
}

export interface Item {
  creationtime?: Date | string;
  modifiedtime?: Date | string;
}

export interface AbstractMediaWsDTO extends Item {
  mime?: string;
  size?: number;
  dataPK?: number;
  location?: string;
  locationHash?: string;
  realFileName?: string;
}

export interface MediaWsDTO extends AbstractMediaWsDTO {
  code?: string;
  url?: URL | string;
  description?: string;
  downloadURL?: URL | string;
  altText?: string;
  documentNumber?: string;
}

export interface PDocumentsWsDTO {
  isBookmarked?: boolean;
  fileName?: string;
  format?: string;
  id?: string;
  location?: string;
  type?: string;
  lastModified?: string | Date;
  thumbnailUrl?: string;
  documentNumber?: string;
}

export interface PDocumentsSearchPageWsDTO extends PSearchPageWsDTO {
  documents?: PDocumentsWsDTO[];
  bookmarkedCount?: number;
}

export interface PStockLevelSearchPageWsDTO extends PSearchPageWsDTO {
  stockLevels?: PStockLevelWsDTO[];
}

export interface PStockLevelWsDTO {
  product?: ProductWsDTO;
  inDispatch?: number;
  lastShipment?: Date;
  planseeStockLevel?: number;
  minimumStockLevel?: number;
  maximumStockLevel?: number;
  segments?: PStockLevelHistoryEntryWsDTO[];
  turnRate?: number;
  consignmentStockLevel?: number;
  unitOfMeasure?: string;
  unitOfMeasureLocalized?: string;
  stockType?: string;
}

export interface PStockLevelHistoryEntryWsDTO {
  entryUpdateDate?: Date;
  entryStockLevel?: number;
  entryForecasted?: number;
  entryShipments?: number;
}

export enum PClaimStatus {
  PARTIALLY_APPROVED = 'E0011',
  CANCELLED = 'E0010',
  NOT_APPROVED = 'E0009',
  APPROVED = 'E0008',
  IN_PROGRESS = 'E0003',
  NO_VALUE = 'E0012'
}

export enum PClaimPreferredAction {
  REWORK = 'REWORK',
  CREDIT_REFUND = 'CREDIT_REFUND',
  REPLACEMENT = 'REPLACEMENT',
  MONETARY_REFUND = 'MONETARY_REFUND'
}

export enum PClaimReportAction {
  NONE = 'NONE',
  PLANSEE_8D_FORM = 'PLANSEE_8D_FORM',
  RETURN_MY_8D = 'RETURN_MY_8D'
}

export interface PClaimTimeline {
  document?: PDocumentsWsDTO;
  type?: string;
  date?: string | Date;
}

interface PClaimEntryWsDTO {
  customerReferenceNumber?: string;
  d8SubmittedDate?: string | Date;
  dateSubmitted?: string;
  headline?: string;
  sapId?: string;
  problemDescription?: string;
  product?: PProductWsDTO;
  status?: PClaimStatus;
  medias?: MediaWsDTO[];
  claimTimelines?: PClaimTimeline[];
}

export interface PClaimWsDTO {
  id?: string;
  claim?: PClaimEntryWsDTO;
}

export interface PClaimFormEntryWsDTO {
  shipmentId?: string;
  shipmentPosition?: string;
  customerReferenceNumber?: string;
  status?: PClaimStatus;
  headline?: string;
  problemDescription?: string;
  dateSubmitted?: string | Date;
  product?: PProductWsDTO;
  reportAction?: PClaimReportAction;
  preferredAction?: PClaimPreferredAction;
  contactPerson?: string;
  affectedAmount?: PAmount;
  affectedPercent?: number;
  alternativeContactEmail?: string;
}

export interface PClaimsSearchPageWsDTO extends PSearchPageWsDTO {
  claims?: PClaimWsDTO[];
}

export interface PClaimForm {
  claim?: PClaimFormEntryWsDTO;
  batchSelectType?: string;
  freeTextSearch?: string;
  stepNumber?: number;
  materialDescription?: string;
  return8Dmedias?: File[];
  claimMedias?: File[];
  orderCode?: string;
  entryNumber?: number;
  unit?: string;
}

export interface PAmount {
  quantity?: number;
  unitOfMeasure?: PUnitWsDTO;
}

export enum PQuotationStatus {
  SUBMITTED = 's0',
  DETERMINING_COSTS = 's1',
  COSTS_DETERMINED = 's2',
  COMPLETE = 's3',
  COMPLETE_PDF_PRINTED = 's4',
  SUBMITTED_V2 = '0',
  DETERMINING_COSTS_V2 = '1',
  COSTS_DETERMINED_V2 = '2',
  COMPLETE_V2 = '3',
  COMPLETE_PDF_PRINTED_V2 = '4'
}

export interface PQuantityEntry {
  quantity?: number;
  unitOfMeasure?: string;
}

export interface PQuotationEntry {
  product?: PProductWsDTO;
  medias?: MediaWsDTO[];
  position?: string;
  additionalServices?: string[];
  additionalInformation?: string;
  customerMaterialNumber?: string;
  quantity?: PQuantityEntry;
  tolerances?: string;
  rejected?: boolean;
}

export interface PQuotationWsDTO {
  id?: string;
  sapId?: string;
  status?: PQuotationStatus;
  title?: string;
  submissionDate?: string | Date;
  dueDate?: string | Date;
  expirationDate?: string | Date;
  entries?: PQuotationEntry[];
  media?: MediaWsDTO;
  hotzoneMedia?: MediaWsDTO;
  dropdown?: boolean;
  isBookmarked?: boolean;
}

export interface PRequestedQuotationEntryWSDTO {
  position?: string;
  customerMaterialNumber?: string;
  planseeMaterialNumber?: string;
  materialType?: string;
  quantity?: PQuantityEntry;
  additionalServices?: string[];
  additionalInformation?: string;
  tolerances?: string;
  parentPosition?: string;
  customUnitOfMeasure?: string;
}

export interface PRequestedQuotationWSDTO {
  id?: string;
  sapId?: string;
  userId?: string;
  title?: string;
  submissionDate?: string | Date;
  expirationDate?: string | Date;
  dueDate?: string | Date;
  status?: PQuotationStatus;
  customerId?: string;
  distributionChannel?: string;
  division?: string;
  salesOrganization?: string;
  entries?: PRequestedQuotationEntryWSDTO[];
}

export interface PQuotationSearchPageWsDTO extends PSearchPageWsDTO {
  quotations?: PQuotationWsDTO[];
}

export enum PEmailFrequency {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  INSTANT = 'INSTANT'
}

export interface PModuleNotificationWsDTO {
  moduleType?: PModuleType;
  module?: string;
  systemAlerts?: boolean;
  emailNotifications?: boolean;
  realTimeNotifications?: boolean;
}

export interface PUserSettingsWsDTO {
  user: PUserWsDTO;
  notifications: PModuleNotificationWsDTO[];
  emailFrequency?: PEmailFrequency;
  emailLanguageIso?: string;
}

export interface PB2bUnitWsDTO {
  companyName?: string;
  customerNumber?: string;
  vatNumber?: string;
  address: AddressWsDTO;
  contactAddress?: PB2UnitContactAddress;
}

export interface PB2bUnitListWsDTO {
  b2bUnits?: PB2bUnitWsDTO[];
  locations?: CountryLocation[];
  disabled?: boolean;
  isAdmin?: boolean;
  isSuperUser?: boolean;
  isSuperUserMaster?: boolean;
  pagination?: PaginationWsDTO;
}

export interface PB2UnitContactInfo {
  formattedAddress?: string;
  phone?: string;
}

export interface PB2UnitContactAddress {
  formattedAddress?: string;
}

export interface PCustomerProfileWsDTO {
  emailFrequency?: PEmailFrequency;
  emailLanguageIso?: string;
  notifications?: PModuleNotificationWsDTO[];
  company?: PB2bUnitWsDTO;
  contactInfo?: PB2UnitContactInfo;
  contactAddress?: PB2UnitContactAddress;
  rootCompany?: PB2bUnitWsDTO;
}

export interface PUnitWsDTO {
  name?: string;
  code?: string;
}

export interface PUnitsWsDTO {
  unitsOfMeasure?: PUnitWsDTO[];
}

export enum PQuotationAditionalServices {
  ASSEMBLY = 'assembly',
  INSTALLATION = 'installation',
  ANALYSIS = 'analysis',
  CERTIFICATES = 'certificates'
}

export interface PNewsWsDTO {
  header?: string;
  headline?: string;
  imageLink?: string | URL;
  position?: number;
  readMoreLink?: string | URL;
  readMoreText?: string;
  text?: string;
}

export interface PNewsListWsDTO {
  recentNews?: PNewsWsDTO[];
}

export interface PEventWsDTO {
  dateFrom?: string | Date;
  dateTo?: string | Date;
  eventLink?: string | URL;
  location?: string;
  title?: string;
}

export interface PEventsListWsDTO {
  events?: PEventWsDTO[];
}

export enum PModuleType {
  QUOTATIONS = 'RFQ',
  ORDERS = 'ORDER',
  SHIPMENTS = 'SHIPMENT',
  FORECASTS = 'FORECASTING',
  CONSIGNMENTS = 'CONSIGNMENT',
  INVOICES = 'INVOICE',
  CLAIMS = 'CLAIM',
  DOCUMENTS = 'DOCUMENT'
}

export interface PKpiWsDTO {
  tile?: string;
  value?: string;
  type?: PModuleType;
}

export interface PKpiListWsDTO {
  kpis?: PKpiWsDTO[];
}

export interface PNotificationWsDTO {
  code?: string;
  date?: string | Date;
  isOld?: boolean;
  isRead?: boolean;
  linkedEntityId?: string;
  linkedEntityType?: PModuleType;
  secondLinkedEntityId?: string;
  secondLinkedEntityType?: PModuleType;
  message?: string;
}

export interface PNotificationsListWsDTO {
  alerts?: PNotificationWsDTO[];
}

export interface PInfo {
  entity?: string;
  field?: string;
  value?: string;
}

export interface PWarning extends PInfo {
  message?: string;
}

export interface PError extends PInfo {
  message?: string;
}

export interface PResponse {
  errors?: PError[];
  infos?: PInfo[];
  warnings?: PWarning[];
}

export enum ChartViewType {
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY'
}

export interface PProductExternalLinksWsDTO {
  items?: PItemCount[];
}

export interface PItemCount {
  count?: number;
  name?: string;
}

export enum PUserType {
  REGULAR = 'REGULAR',
  SUPERUSER = 'SUPERUSER',
  SUPERUSER_MASTER = 'SUPERUSER_MASTER',
}

export interface PCustomerUserWsDTO {
  uid: string;
  creationDate: Date;
  titleCode?: string;
  firstName: string;
  lastName: string;
  email: string;
  address: { phone?: string };
  function: string;
  modules: string[];
  locationList?: CountryLocation[];
  location?: string;
  userType?: string; // = PUserType
  superuser?: boolean; // userType === "REGULAR"
}
export interface PUsersListPageWsDTO extends PSearchPageWsDTO {
  customers: PCustomerUserWsDTO[];
}
 export interface PUserSignUpWsDTO extends UserSignUpWsDTO {
   email?: string;
   position?: string;
   company?: string;
   department?: string;
   function?: string;
   termsAndConditionsAccepted?: boolean;
   address?: AddressWsDTO & {customerNumber?: string, vatNumber?: string};
 }

 export enum PDownloadModalType {
   FORECASTING = 'Forecasting',
   CONSIGNMENT = 'Consignment'
 }

 export interface PDownloadModalOptions {
  stockDevelopment?: boolean;
  forecast?: boolean;
  plannedShipments?: boolean;
  timePeriod?: string;
  confirmedQuantity?: boolean;
 }

 export interface VideoWidgetWsDTO {
   videoUrl?: string;
 }

 export interface Translation {
  [key: string]: string;
 }
