import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from '../../providers/plansee/authorization-service';
import { ConfigService } from '../../providers/plansee/config-service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { SubscriptionManager } from '../../shared/components/subscriptions-manager';
import { combineLatest } from 'rxjs';
import { PlanseeTranslateService } from '../../providers/plansee/p-translate-service';
import { startWith } from 'rxjs/operators';

@Component({
  selector: 'hot-zone-configurator',
  template: require('./hot-zone-configurator.page.html'),
  styles: [require('./hot-zone-configurator.page.scss')],
})
export class HotZoneConfiguratorPageComponent extends SubscriptionManager implements OnInit {

  url: SafeResourceUrl;

  constructor(
    private authorizationService: AuthorizationService,
    private planseeTranslateService: PlanseeTranslateService,
    private configService: ConfigService,
    private sanitizer: DomSanitizer,
  ) {
    super();
  }

  private readonly USER_ID_KEY = 'uid';
  private readonly COMPANY_ID_KEY = 'companyId';

  ngOnInit() {
    this.addSubscriptions(
      combineLatest([
        this.authorizationService.changedCompany.pipe(startWith('')),
        this.authorizationService.changedLocation.pipe(startWith('')),
        this.planseeTranslateService.onLangChange(),
      ])
        .subscribe(([company, _location, _lang]) => {
          this.setUrl();
        })
    );
  }

  private setUrl() {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.prepareUrl());
  }

  private prepareUrl(): string {
    const hotzoneConfiguratorLink = this.configService.config.hotzoneConfiguratorUrl;
    const user = this.authorizationService.getUserProfile();
    const companyId = this.authorizationService.companyId || user.b2bUnit.customerNumber || '';
    if (hotzoneConfiguratorLink && user && user.uid && companyId) {
      const hotzoneConfiguratorUrl = new URL(hotzoneConfiguratorLink);
      hotzoneConfiguratorUrl.searchParams.set(this.USER_ID_KEY, user.uid);
      hotzoneConfiguratorUrl.searchParams.set(this.COMPANY_ID_KEY, companyId);
      return hotzoneConfiguratorUrl.toString();
    }

    return '';
  }
}
