import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {UserIdChoice, UsersService} from '../occ/users-service';
import { PaginationWsDTO, RequestWsDTO, UserGroupListWsDTO, UserWsDTO } from '../types/ycommercewebservices';
import {ConfigService} from './config-service';
import {PB2bUnitListWsDTO, PRequestWsDTO, PUsersListPageWsDTO, PResponse, PCustomerUserWsDTO} from '../types/planseeoccaddon';
import { CustomNgxOauthService } from './custom-ngx-oauth.service';

@Injectable()
export class PUsersService extends UsersService {

  basePath = 'planseecommercewebservices/v2/plansee';

  constructor(http: HttpClient, configService: ConfigService, private oauthService: CustomNgxOauthService) {
    super(http);

    configService.onConfigSet.subscribe(value => {
      if (value && configService.config.apiHost) {
        const apiHost = configService.config.apiHost;
        this.basePath = `${apiHost}/${this.basePath}`;
      }
    });
  }

  getCompanyGroups(userId: UserIdChoice | string, queryParams?: PRequestWsDTO): Observable<UserGroupListWsDTO> {
    return this.get<UserGroupListWsDTO>(`${userId}/rights`, {params: queryParams});
  }

  getB2BUnits(userId: UserIdChoice | string, queryParams?: PaginationWsDTO): Observable<PB2bUnitListWsDTO> {
    return this.get<PB2bUnitListWsDTO>(`${userId}/planseeEmployeeb2bunits`, {params: queryParams});
  }

  setB2BUnit(userId: UserIdChoice | string, b2bunitId: string, queryParams?: RequestWsDTO): Observable<any> {
    // if you are wondering why... ask WNT guys
    let query: any = Object.assign({}, queryParams);
    query.b2bunitId = b2bunitId;
    return this.put(`${userId}/b2bunits`, null, {params: query});
  }

  getSuperuserUsers(queryParams?: PRequestWsDTO): Observable<PUsersListPageWsDTO> {
    const profile: UserWsDTO = this.oauthService.profile;
    return this.get<PUsersListPageWsDTO>(`${encodeURIComponent(profile.uid)}/addedUsers`, {params: queryParams});
  }

  addUser(userData: PCustomerUserWsDTO): Observable<PCustomerUserWsDTO & PResponse> {
    const profile: UserWsDTO = this.oauthService.profile;
    return this.postAt<PCustomerUserWsDTO>(`${encodeURIComponent(profile.uid)}/addUser`, userData);
  }

  editUser(userId: string, userData: PCustomerUserWsDTO): Observable<PCustomerUserWsDTO & PResponse> {
    const profile: UserWsDTO = this.oauthService.profile;
    return this.patch<PCustomerUserWsDTO>(`${encodeURIComponent(profile.uid)}/editUser`, userData);
  }

  deleteUser(userId: string): Observable<void> {
    const profile: UserWsDTO = this.oauthService.profile;
    return this.delete(`${encodeURIComponent(profile.uid)}/deleteUser?uidToBeDeleted=${encodeURIComponent(userId)}`);
  }
}
