import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PB2bUnitWsDTO } from '../../providers/types/planseeoccaddon';
import { AppMode } from '../../providers/types/app-mode';
import { PUsersService } from '../../providers/plansee/p-users-service';
import { distinctUntilChanged, map, scan, switchMap, take } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { SubscriptionManager } from '../../shared/components/subscriptions-manager';
import { isEqual } from 'lodash';

interface ListFilters {
  query: string;
  currentPage: number;
}

@Component({
  selector: 'customer-selection',
  template: require('./customer-selection.component.html'),
  styles: [require('./customer-selection.component.scss')]
})
export class CustomerSelectionComponent extends SubscriptionManager implements OnInit {

  @Input()
  showDismiss = false;
  @Output()
  submit: EventEmitter<PB2bUnitWsDTO> = new EventEmitter();
  @Output()
  setCustomerView: EventEmitter<boolean> = new EventEmitter();
  @Output()
  setNewAppMode: EventEmitter<boolean> = new EventEmitter();
  @Output()
  dismiss: EventEmitter<void> = new EventEmitter();
  @Output()
  companiesLoadedByDefault: EventEmitter<PB2bUnitWsDTO[]> = new EventEmitter();
  @Input()
  current: PB2bUnitWsDTO;
  @Input()
  customerView: boolean;

  @Input()
  set selected(selected: PB2bUnitWsDTO) {
    // decouple
    if (selected && !this._selected) {
      this._selected = selected;
    }
  }

  @Input()
  set currentAppMode(appMode: AppMode) {
    this.newAppMode = appMode === AppMode.NEW_VERSION;
  }

  @Input()
  set userId(userId: string) {
    this._userId = userId;
    this.wasCompaniesLoaded = false;
    this.resetPage();
  }

  get userId(): string {
    return this._userId;
  }

  newAppMode: boolean;
  companies: PB2bUnitWsDTO[] = [];
  shouldDisplayLoadMore = false;

  constructor(
    private usersService: PUsersService
  ) {
    super();
  }

  private resetAcc = false;
  private wasCompaniesLoaded = false;
  private inputTimeout = null;
  private readonly DEFAULT_PAGE_SIZE = 25;
  private currentFilters$ = new BehaviorSubject<ListFilters>({ query: '', currentPage: 0 });
  private _selected: PB2bUnitWsDTO;
  private freeTextSearch = '';
  private _userId: string;

  ngOnInit() {
    this.addSubscriptions(
      this.currentFilters$
      .pipe(
        distinctUntilChanged((a, b) => isEqual(a, b)),
        switchMap((filters) => {
          return this.usersService.getB2BUnits(
            this._userId,
            { currentPage: filters.currentPage, query: filters.query, pageSize: this.DEFAULT_PAGE_SIZE }
          )
            .pipe(take(1));
        }),
        map(returnData => {
          this.shouldDisplayLoadMore = this.currentFilters$.value.currentPage < returnData.pagination.totalPages - 1;
          return Array.isArray(returnData.b2bUnits) ? returnData.b2bUnits : [] as PB2bUnitWsDTO[];
        }),
        scan((acc, chunk) => {
          if (this.resetAcc) {
            this.resetAcc = false;
            return chunk;
          }
          return [...acc, ...chunk];
        }, [] as PB2bUnitWsDTO[]),
      )
      .subscribe(b2bUnits => {
        this.companies = b2bUnits;
        if (!this.wasCompaniesLoaded) {
          this.companiesLoadedByDefault.emit(b2bUnits);
          this.wasCompaniesLoaded = true;
        }
      })
    );
  }

  getBtnBackground(company) {
    return JSON.stringify(company) === JSON.stringify(this._selected) ? 'btn-primary' : 'btn-light';
  }

  getBtnColor(company) {
    return JSON.stringify(company) !== JSON.stringify(this._selected) &&
      JSON.stringify(company) === JSON.stringify(this.current) ? 'text-primary' : '';
  }

  onInput() {
    const searchPhrase = this.freeTextSearch.trim().toLocaleLowerCase();
    clearTimeout(this.inputTimeout);
    this.inputTimeout = setTimeout(() => {
      const currentQuery = this.currentFilters$.value.query;

      if (searchPhrase.length > 1) {
        this.resetAcc = true;
        this.currentFilters$.next({ currentPage: 0, query: searchPhrase });
      } else if (currentQuery !== '') {
        this.resetAcc = true;
        this.currentFilters$.next({ currentPage: 0, query: '' });
      }
    }, 500);
  }

  loadMore() {
    this.currentFilters$.next({
      currentPage: this.currentFilters$.value.currentPage + 1,
      query: this.currentFilters$.value.query
    });
  }

  private resetPage() {
    this.currentFilters$.next({currentPage: 0, query: ''});
  }
}
